import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
            About
          </p>
        </div>
        <p className="text-xl mt-20">
        Hello, I am Alperen Orhan. As a software developer, I have been in the software world for 4 years. I am currently studying at Bursa Technical University and I am constantly improving myself in the field of software.
        </p>
        <br />
        <p className="text-xl">
        I have worked with technologies such as HTML, CSS, Javascript, Php and Bootstrap and I am experienced in these subjects. I especially like to develop with React and React Native, which are Javascript libraries.
        </p>
      </div>
    </div>
  );
};

export default About;
